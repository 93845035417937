import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _10530198 = () => interopDefault(import('../pages/absen/index.vue' /* webpackChunkName: "pages/absen/index" */))
const _29e8c533 = () => interopDefault(import('../pages/bahan/index.vue' /* webpackChunkName: "pages/bahan/index" */))
const _c799b316 = () => interopDefault(import('../pages/bank/index.vue' /* webpackChunkName: "pages/bank/index" */))
const _2e9a915e = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _08cd89c4 = () => interopDefault(import('../pages/draft/index.vue' /* webpackChunkName: "pages/draft/index" */))
const _6d826558 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _ddaeed34 = () => interopDefault(import('../pages/index_catalog.vue' /* webpackChunkName: "pages/index_catalog" */))
const _a515b1a4 = () => interopDefault(import('../pages/jadwal/index.vue' /* webpackChunkName: "pages/jadwal/index" */))
const _2360bf03 = () => interopDefault(import('../pages/kasir/index.vue' /* webpackChunkName: "pages/kasir/index" */))
const _22a009c4 = () => interopDefault(import('../pages/katalog/index.vue' /* webpackChunkName: "pages/katalog/index" */))
const _135c3bfd = () => interopDefault(import('../pages/konfigurasi/index.vue' /* webpackChunkName: "pages/konfigurasi/index" */))
const _7b98979c = () => interopDefault(import('../pages/kunjungan/index.vue' /* webpackChunkName: "pages/kunjungan/index" */))
const _6fa935e8 = () => interopDefault(import('../pages/laporan/index.vue' /* webpackChunkName: "pages/laporan/index" */))
const _f79a5d0a = () => interopDefault(import('../pages/layanan/index.vue' /* webpackChunkName: "pages/layanan/index" */))
const _0f6df8b6 = () => interopDefault(import('../pages/multi/index.vue' /* webpackChunkName: "pages/multi/index" */))
const _61c047b0 = () => interopDefault(import('../pages/note.vue' /* webpackChunkName: "pages/note" */))
const _159b4318 = () => interopDefault(import('../pages/panduan/index.vue' /* webpackChunkName: "pages/panduan/index" */))
const _47f3ce7a = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _75d8052d = () => interopDefault(import('../pages/pegawai/index.vue' /* webpackChunkName: "pages/pegawai/index" */))
const _4be4870e = () => interopDefault(import('../pages/pelanggan/index.vue' /* webpackChunkName: "pages/pelanggan/index" */))
const _6e2257d9 = () => interopDefault(import('../pages/pembayaran/index.vue' /* webpackChunkName: "pages/pembayaran/index" */))
const _5742c8e8 = () => interopDefault(import('../pages/pengajuan/index.vue' /* webpackChunkName: "pages/pengajuan/index" */))
const _7fe13c38 = () => interopDefault(import('../pages/penjadwalan/index.vue' /* webpackChunkName: "pages/penjadwalan/index" */))
const _10a2eada = () => interopDefault(import('../pages/pinjam/index.vue' /* webpackChunkName: "pages/pinjam/index" */))
const _178660b4 = () => interopDefault(import('../pages/produk/index.vue' /* webpackChunkName: "pages/produk/index" */))
const _09d3cef5 = () => interopDefault(import('../pages/profil/index.vue' /* webpackChunkName: "pages/profil/index" */))
const _7e039808 = () => interopDefault(import('../pages/riwayat/index.vue' /* webpackChunkName: "pages/riwayat/index" */))
const _4eb6ba9a = () => interopDefault(import('../pages/rutin/index.vue' /* webpackChunkName: "pages/rutin/index" */))
const _f0fedf34 = () => interopDefault(import('../pages/saldo/index.vue' /* webpackChunkName: "pages/saldo/index" */))
const _3e3a6e82 = () => interopDefault(import('../pages/shift/index.vue' /* webpackChunkName: "pages/shift/index" */))
const _40bbf730 = () => interopDefault(import('../pages/titipan/index.vue' /* webpackChunkName: "pages/titipan/index" */))
const _7518ecd0 = () => interopDefault(import('../pages/toko/index.vue' /* webpackChunkName: "pages/toko/index" */))
const _4d3ada65 = () => interopDefault(import('../pages/transaksi/index.vue' /* webpackChunkName: "pages/transaksi/index" */))
const _13f15817 = () => interopDefault(import('../pages/auth/check.vue' /* webpackChunkName: "pages/auth/check" */))
const _798a56a4 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _d4956c10 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _baf1085c = () => interopDefault(import('../pages/auth/success.vue' /* webpackChunkName: "pages/auth/success" */))
const _6e9df60a = () => interopDefault(import('../pages/jadwal/desktop/index.vue' /* webpackChunkName: "pages/jadwal/desktop/index" */))
const _71cb0b7f = () => interopDefault(import('../pages/jadwal/new/index.vue' /* webpackChunkName: "pages/jadwal/new/index" */))
const _6273b200 = () => interopDefault(import('../pages/jadwal/tambah/index.vue' /* webpackChunkName: "pages/jadwal/tambah/index" */))
const _8a9912fa = () => interopDefault(import('../pages/kunjungan/new/index.vue' /* webpackChunkName: "pages/kunjungan/new/index" */))
const _56b18b89 = () => interopDefault(import('../pages/penjadwalan/new/index.vue' /* webpackChunkName: "pages/penjadwalan/new/index" */))
const _448a084c = () => interopDefault(import('../pages/jadwal/new/desktop/index.vue' /* webpackChunkName: "pages/jadwal/new/desktop/index" */))
const _49f214e2 = () => interopDefault(import('../pages/jadwal/new/tambah/index.vue' /* webpackChunkName: "pages/jadwal/new/tambah/index" */))
const _23f52fbc = () => interopDefault(import('../pages/jadwal/new/desktop/tambah/index.vue' /* webpackChunkName: "pages/jadwal/new/desktop/tambah/index" */))
const _7d32b38c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _b3a4bad6 = () => interopDefault(import('../pages/jadwal/new/desktop/ubah/_id.vue' /* webpackChunkName: "pages/jadwal/new/desktop/ubah/_id" */))
const _327e22fc = () => interopDefault(import('../pages/jadwal/new/ubah/_id.vue' /* webpackChunkName: "pages/jadwal/new/ubah/_id" */))
const _341e4bae = () => interopDefault(import('../pages/transaksi/payment/multi/_id.vue' /* webpackChunkName: "pages/transaksi/payment/multi/_id" */))
const _8be5691e = () => interopDefault(import('../pages/transaksi/payment/pembayaran/_id.vue' /* webpackChunkName: "pages/transaksi/payment/pembayaran/_id" */))
const _f1a348ce = () => interopDefault(import('../pages/auth/reset/_slug.vue' /* webpackChunkName: "pages/auth/reset/_slug" */))
const _4a943700 = () => interopDefault(import('../pages/invoice/print/_slug.vue' /* webpackChunkName: "pages/invoice/print/_slug" */))
const _1f6c8073 = () => interopDefault(import('../pages/jadwal/ubah/_id.vue' /* webpackChunkName: "pages/jadwal/ubah/_id" */))
const _ba955ff8 = () => interopDefault(import('../pages/transaksi/payment/_id.vue' /* webpackChunkName: "pages/transaksi/payment/_id" */))
const _e0443794 = () => interopDefault(import('../pages/feedback/_slug.vue' /* webpackChunkName: "pages/feedback/_slug" */))
const _7c3f83e2 = () => interopDefault(import('../pages/invoice/_slug.vue' /* webpackChunkName: "pages/invoice/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/absen",
    component: _10530198,
    name: "absen"
  }, {
    path: "/bahan",
    component: _29e8c533,
    name: "bahan"
  }, {
    path: "/bank",
    component: _c799b316,
    name: "bank"
  }, {
    path: "/dashboard",
    component: _2e9a915e,
    name: "dashboard"
  }, {
    path: "/draft",
    component: _08cd89c4,
    name: "draft"
  }, {
    path: "/home",
    component: _6d826558,
    name: "home"
  }, {
    path: "/index_catalog",
    component: _ddaeed34,
    name: "index_catalog"
  }, {
    path: "/jadwal",
    component: _a515b1a4,
    name: "jadwal"
  }, {
    path: "/kasir",
    component: _2360bf03,
    name: "kasir"
  }, {
    path: "/katalog",
    component: _22a009c4,
    name: "katalog"
  }, {
    path: "/konfigurasi",
    component: _135c3bfd,
    name: "konfigurasi"
  }, {
    path: "/kunjungan",
    component: _7b98979c,
    name: "kunjungan"
  }, {
    path: "/laporan",
    component: _6fa935e8,
    name: "laporan"
  }, {
    path: "/layanan",
    component: _f79a5d0a,
    name: "layanan"
  }, {
    path: "/multi",
    component: _0f6df8b6,
    name: "multi"
  }, {
    path: "/note",
    component: _61c047b0,
    name: "note"
  }, {
    path: "/panduan",
    component: _159b4318,
    name: "panduan"
  }, {
    path: "/payment",
    component: _47f3ce7a,
    name: "payment"
  }, {
    path: "/pegawai",
    component: _75d8052d,
    name: "pegawai"
  }, {
    path: "/pelanggan",
    component: _4be4870e,
    name: "pelanggan"
  }, {
    path: "/pembayaran",
    component: _6e2257d9,
    name: "pembayaran"
  }, {
    path: "/pengajuan",
    component: _5742c8e8,
    name: "pengajuan"
  }, {
    path: "/penjadwalan",
    component: _7fe13c38,
    name: "penjadwalan"
  }, {
    path: "/pinjam",
    component: _10a2eada,
    name: "pinjam"
  }, {
    path: "/produk",
    component: _178660b4,
    name: "produk"
  }, {
    path: "/profil",
    component: _09d3cef5,
    name: "profil"
  }, {
    path: "/riwayat",
    component: _7e039808,
    name: "riwayat"
  }, {
    path: "/rutin",
    component: _4eb6ba9a,
    name: "rutin"
  }, {
    path: "/saldo",
    component: _f0fedf34,
    name: "saldo"
  }, {
    path: "/shift",
    component: _3e3a6e82,
    name: "shift"
  }, {
    path: "/titipan",
    component: _40bbf730,
    name: "titipan"
  }, {
    path: "/toko",
    component: _7518ecd0,
    name: "toko"
  }, {
    path: "/transaksi",
    component: _4d3ada65,
    name: "transaksi"
  }, {
    path: "/auth/check",
    component: _13f15817,
    name: "auth-check"
  }, {
    path: "/auth/forgot",
    component: _798a56a4,
    name: "auth-forgot"
  }, {
    path: "/auth/login",
    component: _d4956c10,
    name: "auth-login"
  }, {
    path: "/auth/success",
    component: _baf1085c,
    name: "auth-success"
  }, {
    path: "/jadwal/desktop",
    component: _6e9df60a,
    name: "jadwal-desktop"
  }, {
    path: "/jadwal/new",
    component: _71cb0b7f,
    name: "jadwal-new"
  }, {
    path: "/jadwal/tambah",
    component: _6273b200,
    name: "jadwal-tambah"
  }, {
    path: "/kunjungan/new",
    component: _8a9912fa,
    name: "kunjungan-new"
  }, {
    path: "/penjadwalan/new",
    component: _56b18b89,
    name: "penjadwalan-new"
  }, {
    path: "/jadwal/new/desktop",
    component: _448a084c,
    name: "jadwal-new-desktop"
  }, {
    path: "/jadwal/new/tambah",
    component: _49f214e2,
    name: "jadwal-new-tambah"
  }, {
    path: "/jadwal/new/desktop/tambah",
    component: _23f52fbc,
    name: "jadwal-new-desktop-tambah"
  }, {
    path: "/",
    component: _7d32b38c,
    name: "index"
  }, {
    path: "/jadwal/new/desktop/ubah/:id?",
    component: _b3a4bad6,
    name: "jadwal-new-desktop-ubah-id"
  }, {
    path: "/jadwal/new/ubah/:id?",
    component: _327e22fc,
    name: "jadwal-new-ubah-id"
  }, {
    path: "/transaksi/payment/multi/:id?",
    component: _341e4bae,
    name: "transaksi-payment-multi-id"
  }, {
    path: "/transaksi/payment/pembayaran/:id?",
    component: _8be5691e,
    name: "transaksi-payment-pembayaran-id"
  }, {
    path: "/auth/reset/:slug?",
    component: _f1a348ce,
    name: "auth-reset-slug"
  }, {
    path: "/invoice/print/:slug?",
    component: _4a943700,
    name: "invoice-print-slug"
  }, {
    path: "/jadwal/ubah/:id?",
    component: _1f6c8073,
    name: "jadwal-ubah-id"
  }, {
    path: "/transaksi/payment/:id?",
    component: _ba955ff8,
    name: "transaksi-payment-id"
  }, {
    path: "/feedback/:slug?",
    component: _e0443794,
    name: "feedback-slug"
  }, {
    path: "/invoice/:slug?",
    component: _7c3f83e2,
    name: "invoice-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
